import Head from "next/head";
import "../styles/globals.css";
import { SessionProvider } from "next-auth/react";
import dotenv from "dotenv";

// Load environment variables from .env if it exists
dotenv.config();


function App({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <SessionProvider session={session}>
    <>
      <Head>
        <link rel="icon" href="/favicon.png" />
      </Head>
     
      <Component {...pageProps} />
    </>
    </SessionProvider>
  );
}

export default App;
